.filter-chip {
    display: flex;
    align-items: center;
    padding: 3px 8px 3px 6px; /* Adds padding around the content */
    border: 1px dashed #d8dee4; /* Adds dashed border around the chip */
    border-radius: 20px; /* Rounded corners */
    column-gap: 8px;
    font-size: 14px; /* Font size for text */
    color: #353a44; /* Text color */
    cursor: pointer; /* Indicates it's clickable */
    gap: 5px; /* Space between the icon and text */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    max-height: 27px;
    white-space: nowrap;
}

.filter-chip:hover {
    background-color: #f3f3f3; /* Background color change on hover */
}

#gear-icon {
    width: 12px;
    height: 12px;
    color: #7e8285; /* Icon color */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Aligns modal content to the top */
    padding-top: 120px; /* Adjusts the distance from the top */
    z-index: 999; /* Ensures overlay is on top */
  }

  .modal-overlay-2 {
    position: fixed;
    top: 0px;
    left: -1px;
    right: 0;
    bottom: 0;
    
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center; /* Aligns modal content to the top */
    z-index: 999; /* Ensures overlay is on top */
  }

  .modal-overlay-3 {
    position: fixed;
    top: 120px;
    left: 16px;
    background-color: rgba(0, 0, 0, 0); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Aligns modal content to the top */
    
    z-index: 999; /* Ensures overlay is on top */
  }

  
  .modal-content {
    
    background-color: white;
    padding: 8px;
    border-radius: 8px;
    width: 300px;
    border: 1px solid #d8dee4;
    position: absolute;
    opacity: 0; /* Start hidden */
    transform: translateY(-10px); /* Start slightly above */
    transition: opacity 1s ease, transform 0.3s ease; /* Transition for opacity and position */
  }

  .modal-content-2 {
    
    background-color: white;
    padding: 0px 0px;
    border-radius: 8px;
    width: 300px;
    border: 1px solid #d8dee4;
    
    opacity: 0; /* Start hidden */
    /*transform: translateY(-10px);  Start slightly above */
    transition: opacity 1s ease, transform 0.3s ease; /* Transition for opacity and position */
  }

  .modal-content-3 {
    background-color: white;
    
    border-radius: 0px;
    width: 213px;
    height: 300px;
    border: 1px solid #d8dee4;
  }

  .modal-content-2-header {
    display: flex;
    font-style: arial;
    padding: 16px;
    height: 57px;
    border-bottom: 1px solid #d8dee4
  }

  .modal-content-2-header-2 {
    padding: 16px;
    height: 57px;
    border-bottom: 1px solid #d8dee4
  }

  .modal-content-2-body {
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    
  }
  .modal-content-2-body-container {
    max-height: 550px;
    overflow-y: auto;
    scrollbar-width: thin;
  }
  

  .modal-content.show {
    opacity: 1; /* Fully visible */
    transform: translateY(0); /* Slide to position */
  }

  .modal-content-2.show {
    opacity: 1; /* Fully visible */
     /* Slide to position */
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  
  
  .close-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .modal-body {
    margin-bottom: 10px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: space-between;
  }
  
 .filtered-list-container {
  max-height: 264px;
  overflow-y: auto;
  scrollbar-width: thin;
 }

 .filtered-list-container ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
 }

 .filtered-list-container li {
  font-size: 13px;
  padding: 9.5px 5px 9.5px 5px
 }

 .filtered-list-container li:hover {
  cursor: pointer;
  background-color: #eee
 }



 .general-center-style {
  align-items:center;
  justify-content:center;
  display:flex;
 }

 .general-right-style {
  align-items:center;
  justify-content:right;
  display:flex;
 }