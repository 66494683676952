.page-container {
    height: 100%;
    display: flex;
}
html, body.bs2, body.bs3, .page-container {
    padding: 0;
    margin: 0;
}


.main-content2 {
    width: 100%;
    height: 100vh;
}

@media (min-width: 768px) {
    .main-content2 {
        flex: 1 1 100%;
        display: flex;
        flex-wrap: wrap;
    }
}

#right-panel {
    color: #5E6871;
}

.sign-up-container {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.sign-up-container.visible {
    animation: fadeIn 0.5s forwards;
}

.left-panel-container, .right-panel-container {
    width: 320px;
    margin: auto;
}

.left-panel-container {
    transition: margin 0.5s ease-in-out;
    overflow-y: auto;
    max-height: 100vh;
}

#left-panel {
    align-items: center;
    display: flex;
    height: 100vh;
}

@media (min-width: 992px) {
    .col-md-4 {
        width: 33.33333333%;
    }
}

@media (min-width: 768px) {
    .col-sm-5 {
        width: 41.66666667%;
    }
}

@media (min-width: 992px) {
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
        float: left;
    }
}


@media (min-width: 768px) {
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
        float: left;
    }
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}



@media (min-width: 768px) {
    #right-panel:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.5;
        background-image: url("trianglifytest.png");
        background-repeat: no-repeat;
        -ms-background-size: cover;
        -o-background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
        background-size: cover;
        
       
    }
}


*:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

@media (min-width: 768px) {
    #right-panel.darkbg {
        color: #fff;
        background-color: #1D1D21;
    }
}


@media (min-width: 992px) {
    .right-panel-image-slant {
        position: relative;
        display: inline-block;
        padding: 1em 7em 1em 1em;
        overflow: hidden;
        color: #000;
        width: 0px;
        height: 100%;
        z-index: 2;
        transition: padding 0.5s ease, width 0.5s ease; /* Combine transitions */
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .right-panel-image-slant {
        position: relative;
        display: inline-block;
        padding: 0;
        overflow: hidden;
        color: #000;
        width: 0px;
        height: 100vh;
        z-index: 2;
        transition: padding 0.5s ease, width 0.5s ease; /* Combine transitions */
    }
}



@media (min-width: 992px) {
    .right-panel-image-slant:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: #fff;
        -webkit-transform-origin: 100% 0;
        -ms-transform-origin: 100% 0;
        transform-origin: 100% 0;
        -webkit-transform: skew(-5deg);
        -ms-transform: skew(-5deg);
        transform: skew(-5deg);
        z-index: -1;
        transition: transform 0.5s ease; /* Add this line for transition */
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .right-panel-image-slant:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: #fff;
        z-index: -1;
        -webkit-transform-origin: 100% 0;
        -ms-transform-origin: 100% 0;
        transform-origin: 100% 0;
        -webkit-transform: skew(0deg); /* Add this line to reset the transform */
        -ms-transform: skew(0deg);     /* Add this line to reset the transform */
        transform: skew(0deg);         /* Add this line to reset the transform */
        transition: transform 0.5s ease; /* Add this line for transition */
    }
}


.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    padding-right: 7.5px;
    padding-left: 7.5px;
}
@media (min-width: 992px) {
    .col-md-8 {
        width: 66.66666667%;
    }
}
@media (min-width: 992px) {
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
        float: left;
    }
}
@media (min-width: 768px) {
    .col-sm-7 {
        width: 58.33333333%;
    }
}
@media (min-width: 768px) {
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
        float: left;
    }
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-sm-7, .col-md-8 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 0px;
}




* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


div {
    display: block;
    unicode-bidi: isolate;
}