
.supplier-body-entry-container {
    min-height: calc(100vh - 72px);
    width: 100%;
}

.filter-bar-container {
    
    width: 100%;
    border-top: 1px solid #d8dee4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px 0px 16px;
}

.filter-bar {
    min-height: 30px;
    max-height: 30px;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;    
    overflow: hidden;
}

.part-type-container {
    font-size: 12px;
    padding: 0px 16px 8px 16px;
    border-bottom: 1px solid #d8dee4;
    font-weight: bold;
    color: #339AF0
}

.part-type-button-2 {
    font-size: 12px;
    font-weight: bold;
    color: #339AF0;
    outline: none;
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
}

.filter-bar-contents {
    min-height: 30px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: left;
    gap: 8px;
    margin-right: 10px;
}

.filter-bar-contents:first-child {
    gap: 16px;
}

.filter-by-id-input {
    min-height: 30px;
    width: 200px;
    padding: 4px 24px 4px 28px;
    border: 1px solid #d8dee4;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    outline: none;
}

#magnifying-glass {
    position: absolute;
    width: 12px;
    height: 12px;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #353a44;
}

#right-to-bracket {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 178px;
    top: 50%;
    transform: translateY(-50%);
    color: #7e8285;
}

#right-to-bracket:hover {
    color: #4c5562;
    cursor: pointer
}

.filter-chip-container {
    min-height: 30px;
    max-height: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 8px;
    position: relative;
    overflow: hidden;
}

.clear-filters-button,
.search-filters-button {
    
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #339AF0;
    font-weight: bold;
}

.search-filters-button {
    margin-left: auto;
}


.equipment-table-body {
    padding: 16px;
}

.equipment-table-body td {
    border-radius: 8px;
    border: 1px solid transparent
}

.equipment-table-body tr {
    border-bottom: 1px solid #d8dee4;
}

.equipment-table-body tr:hover {
    border-bottom: 1px solid transparent;
}

.equipment-table-body td:hover {
    cursor: pointer;
    background-color: #d8dee4;
    border-bottom: 'none'
}

.equipment-table-body td,
.equipment-table-body tr {
    padding: 8px;
    font-size: 14px;
}

.equipment-table-body td {
    display: flex;
  justify-content: space-between; /* Pushes spans to the sides */
  align-items: center;
}

.equipment-table-overflow-container {
    padding-left: 16px;
    max-height: calc(100vh - 126px);
    overflow-y: auto;
    scrollbar-width: thin;
}



.equipment-table-positioning-container {
    padding: 16px 16px 24px 0px;
}

.equipment-table-span-1 {
    display: inline-flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.equipment-table-span-3 {
    font-size: 12px;
    color: #6c7688;
}

.equipment-table-span-2 {
    color: #353A44;
    font-size: 14px;
    
}

.equipment-table-span-4 {
    margin-left: auto;
    color: #6c7688;
}

.monostyle-text {
    font-family: 'Inconsolata', monospace;
}


 /* supplier body css */

   .supplier-equipment-body {
    min-height: calc(100vh - 126px);
    display: flex;
    flex-direction: row;
   }
  
   .supplier-equipment-left-panel {
    width: 100%;
   }
  
   .supplier-equipment-right-panel {
    width: 100%;
    border-left: 1px solid #d8dee4;
   }

   @media (max-width: 490px) {

    .equipment-table-overflow-container {
        max-height: calc(95vh - 126px);
    }
    .supplier-equipment-body {
        overflow: hidden; /* Prevents overflow during transition */
        position: relative; /* Allows absolute positioning within the container */
      }
    .supplier-equipment-left-panel {
        width: 100%;
        transition: transform 0.3s ease;
        position: relative;
    }
    .supplier-equipment-right-panel {
        width: 100%;
        height: 100%;
        border-left: 1px solid #d8dee4;
        position: absolute; /* Stacks panels on top of each other */
        top: 0px;
        
        right: -100%; /* Hides the right panel initially */
        transition: transform 0.3s ease;
    }
    .supplier-equipment-left-panel.active {
        transform: translateX(-100%); /* Moves left panel out of view */
      }
    
    .supplier-equipment-right-panel.active {
        transform: translateX(-100%); /* Brings right panel into view */
    }
   }




   .module-span-1 {
    display: flex; 
    gap: 10px;
   }

   .module-span-2 {
    width: 110px;
    text-align: left;
   }


   .module-container {
    display: 'flex'; 
    flex-direction: 'column'; 
    width: '100%';
    font-size: 12px;
    color: #353A44;
    font-family: arial;
    margin-bottom: 10px;
   }

   .right-panel-span-1 {
    color: #6c7688;
    font-size: 12px;
    width: 100%;
   }

   .supplier-equipment-right-panel-overflow-container {
    max-height: calc(100vh - 126px);
    overflow-y: auto;
    scrollbar-width: thin;
    padding-left: 16px;
   }

   .right-panel-positioning-container {
    padding: 16px 0px 16px 0px;

   }

   .nocontent-positioning-container {
    padding: 16px;
    height: 100%;
   }

   .part-image-container {
    float: left;
    height: 100px; 
    min-width: 100px; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    margin-right: 10px;
   }

   .part-description-container {
    overflow: hidden;
    min-height: 100px;
    width: 100%;
    height: auto;
    
    padding-right: 16px;
    font-size: 12px;
    font-style: arial;
    margin-bottom: 10px;
   }

   .custom-header-1 {
    font-size: 12px;
    font-style: arial;
    border-bottom: 1px #d8dee4 solid;
    margin-bottom: 10px;
   }

   .custom-header-2 {
    font-size: 12px;
    font-style: arial;
   }

   .custom-header-3 {
    
    font-size: 12px;
    font-style: arial;
    border-bottom: 1px #d8dee4 solid;
    padding-bottom: 8px;
   }

   .load-more-button {
    padding: 24px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #339AF0;
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
   }

   .product-cart-container{
    align-items: center;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
   }

   .product-cart-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 34px;
        border: 1px solid #d8dee4;
        margin-right: 5px;
        border-radius: 4px;
        cursor: pointer;
    }

    .product-cart-button:hover {
        background-color: #eee;
    }

.product-description-container {
    border-bottom: 1px solid #d8dee4;
    width: 100%;
    display: flex;
}



    .add-to-cart-button {
        border: 1px solid #d8dee4;
        border-radius: 6px;
        padding: 8px;
        transition: background-color 250ms ease
    }

    .add-to-cart-button:hover {
        cursor: pointer;
        background-color: #d8dee4;
        
    }




    @media (max-width: 400px) {
        .hide-on-small-screen {
          display: none;
        }
      }


    .no-hover-style {
        cursor: default;
        background-color: transparent;
        pointer-events: none;
    }

    @media (max-width: 490px) {
        .no-hover-style {
            pointer-events: auto;
        }
    }




    /* config section */
    .configuration-selections {
        width: '130px';
    }

    .configuration-options {
        width: 100%;
        
    }



    
    
    


        .config-container {
            position: relative;
            overflow: hidden;
            width: 100%;
            display: flex;
            transition: transform 0.3s ease;
          }
          
          .config-selections,
          .config-options {
            width: 100%;
            flex-shrink: 0;
            transition: transform 0.3s ease;
          }